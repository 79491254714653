import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/solutions/components/HeroSection';
import Advantages from '../../sections/solutions/components/Advantages';
import ExpertSolutions from '../../sections/solutions/components/ExpertSolutions';
import TrustAuthenticateSection from '../../sections/solutions/kyc-aml-compliance/TrustAuthenticateSection';
import PrivacyProtections from '../../sections/solutions/kyc-aml-compliance/PrivacyProtections';
import Resources from '../../components/reusable/Resources';
import {
    KYC_AML_COMPLIANCE_PAGE_RESOURCES,
    KYC_AML_FAQ,
    COMMON_BOTTOM_BANNER_CONTENTS,
    SOLUTIONS_TEXT_TO_ANIMATE,
    KYC_AML_AND_IFP_ADVANTAGES,
    KYC_SOLUTIONS,
    BACKGROUND_CHECKS,
} from '../../sections/solutions/utilities';
import HERO_ANIMATED_BG from '../../lotties/solutions_page_hero_bg.json';
import HERO_IMAGE from '../../images/product-assets/Solutions_Hero_image.svg';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import BottomBanner from '../../components/reusable/BottomBanner';
import MetaTagsProvider from '../../components/MetaTagsProvider';
import Vendors from '../../components/reusable/vendors/Index';
import Banner from '../../sections/solutions/components/Banner';
import { KycAmlBannerCta } from '../../sections/solutions/components/BannerCta';
const Solutions = () => {
    return (
        <RootLayout>
            <MetaTagsProvider
                title="Authenticate: KYC & AML Compliance Solutions"
                description="Simplify KYC/AML compliance with Authenticate's identity verification and risk assessment. Ensure regulatory compliance and prevent fraud with fast, seamless onboarding."
                keywords="KYC compliance, AML compliance, identity verification, risk assessment, regulatory compliance, customer onboarding, fraud prevention, compliance solutions, anti-money laundering, Know Your Customer"
                path="solutions/kyc-aml-compliance"
                ogImage="https://cdn.authenticating.com/public/website/product_pages/kyc-aml-og-img.png"
                ogDescription="Authenticate provides advanced KYC/AML compliance solutions with instant identity verification and risk assessment. Mitigate fraud with instant ID verification and meet Know Your Customer and AML regulations."
                twitterDescription="Streamline KYC and AML compliance with Authenticate's identity verification and risk assessment solutions. Prevent fraud and ensure compliance with fast, seamless onboarding."
            />
            <HeroSection
                pageTitle="KYC & AML"
                heading="Streamlined Global KYC & Automated AML Compliance for"
                subHeading="Simplify Your KYC/AML Compliance with Authenticate®: Verify Users Instantly, Prevent Fraud, and Fulfill All Compliance Needs Effortlessly."
                textToAnimate={SOLUTIONS_TEXT_TO_ANIMATE}
                animatedBgImage={HERO_ANIMATED_BG}
                heroImage={HERO_IMAGE}
                animatedBg
            />
            <Vendors />
            <Advantages content={KYC_AML_AND_IFP_ADVANTAGES} />
            <ExpertSolutions
                heading=" Secure and Simplify Compliance with Expert Solutions"
                data1={KYC_SOLUTIONS}
                data2={BACKGROUND_CHECKS}
                link1="/products/identity"
                link2="/products/criminal-background-checks"
            />
            <Banner
                heading="Optimize Your Workflow with Fast and Scalable Identity Verification APIs"
                subText="Experience seamless KYC and AML compliance. Verify users in just 30 seconds and save up to 73% on verification costs!"
                ctaComponent={<KycAmlBannerCta />}
            />
            <TrustAuthenticateSection />
            <PrivacyProtections />
            <Resources item={KYC_AML_COMPLIANCE_PAGE_RESOURCES} page="KYC/AML" />
            <ProductFaq
                faqData={KYC_AML_FAQ}
                subHeading="Everything you need to know about KYC/AML Compliance."
                titleColor="#262626"
                animation
            />
            <BottomBanner data={COMMON_BOTTOM_BANNER_CONTENTS} />
        </RootLayout>
    );
};

export default Solutions;
